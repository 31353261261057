var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pickl && _vm.pickl.id
    ? _c(
        "div",
        {
          staticClass: "green-border-box white-bg text-center pickl",
          class: { disabled: _vm.disabled }
        },
        [
          _c("div", { staticClass: "top-part" }, [
            _c("div", { staticClass: "posRel" }, [
              _c("div", { staticClass: "grey-logo-circle company-logo" }, [
                _c("img", {
                  staticClass: "img-in-circle",
                  attrs: { src: "/img/at_home_pickl_icon.png" }
                })
              ]),
              _c("div", { staticClass: "grey-logo-circle product-logo" }, [
                _c("img", {
                  staticClass: "img-in-circle",
                  attrs: { src: _vm.pickl.brand.logo + "?w=150&q=50" },
                  on: {
                    error: function($event) {
                      _vm.pickl.brand.logo = "/img/pickl-logo.png"
                    }
                  }
                })
              ]),
              _c(
                "h4",
                {
                  directives: [
                    {
                      name: "autosize",
                      rawName: "v-autosize",
                      value: 22,
                      expression: "22"
                    }
                  ],
                  attrs: { title: "@home Pickl" }
                },
                [_vm._v(_vm._s(_vm.pickl.name))]
              ),
              _c(
                "h5",
                {
                  directives: [
                    {
                      name: "autosize",
                      rawName: "v-autosize",
                      value: 17,
                      expression: "17"
                    }
                  ],
                  attrs: { title: _vm.pickl.brand.name }
                },
                [_vm._v(_vm._s(_vm.pickl.brand.name))]
              )
            ])
          ]),
          _c("div", { staticClass: "middle-part" }, [
            _vm.pickl.rating
              ? _c(
                  "div",
                  { staticClass: "rating" },
                  _vm._l(_vm.pickl.rating, function(n) {
                    return _c("img", {
                      key: n,
                      attrs: { src: "/img/Alerts/picklerating_icon.png" }
                    })
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "status",
                staticStyle: { "margin-bottom": "10px" },
                style: { color: _vm.statusColor }
              },
              [
                _vm._v(
                  "Responses Left " +
                    _vm._s(_vm.pickl.responses_left) +
                    "/" +
                    _vm._s(_vm.pickl.response_counter) +
                    " "
                ),
                _vm.pickl.responses_expired > 0
                  ? _c("span", [
                      _vm._v(
                        "(Expired: " + _vm._s(_vm.pickl.responses_expired) + ")"
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              { staticClass: "status", style: { color: _vm.statusColor } },
              [
                _vm._v(
                  "Launched: " +
                    _vm._s(_vm.pickl.start_date) +
                    " @ " +
                    _vm._s(_vm.pickl.start_time)
                )
              ]
            ),
            _vm.pickl.status == "PENDING" || _vm.pickl.status == "ACCEPTED"
              ? _c(
                  "div",
                  { staticClass: "status", style: { color: _vm.statusColor } },
                  [
                    _vm._v(
                      "Expiring In: " + _vm._s(_vm.pickl.expiring_in) + " Days"
                    )
                  ]
                )
              : _vm._e(),
            _vm.pickl.status == "EXPIRED"
              ? _c(
                  "div",
                  { staticClass: "status", style: { color: _vm.statusColor } },
                  [_vm._v("Expired")]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "status", style: { color: _vm.statusColor } },
              [_vm._v(_vm._s(_vm.statusText))]
            )
          ]),
          _c("div", { staticClass: "bottom-part" }, [
            _c("div", { staticClass: "col-xs-12 col-sm-6 pickl-date" }, [
              _c("img", { attrs: { src: "/img/Picklr/date_icon.png" } }),
              _vm._v(" " + _vm._s(_vm.pickl.date) + "\n        ")
            ]),
            _c("div", { staticClass: "col-xs-12 col-sm-6 pickl-date" }, [
              _c("img", { attrs: { src: "/img/Picklr/time_icon.png" } }),
              _vm._v(" " + _vm._s(_vm.pickl.time) + "\n            "),
              _vm.pickl.extend_counter > 0
                ? _c(
                    "span",
                    {
                      staticClass: "pull-right",
                      attrs: {
                        tip: "",
                        title: "Extend counter: " + _vm.pickl.extend_counter
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "mr3",
                        staticStyle: { "margin-top": "3px" },
                        attrs: {
                          "data-v-4cc8d1bb": "",
                          src: "/img/Alerts/timeGreen_icon.png"
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            background: "rgb(18, 219, 109)",
                            "border-radius": "150px",
                            color: "white",
                            "text-align": "center",
                            padding: "0 6px"
                          }
                        },
                        [_vm._v(_vm._s(_vm.pickl.extend_counter))]
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "clearfix" })
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

export interface Pickl {
  id: number,
  brand: {
    name: string,
    logo: string
  },
  store: {
    image: string,
    address: string
  },
  // eslint-disable-next-line
  updated_at: string, processing_time: string,
  status: string,
  rating: number
}

@Component
export default class PicklItem extends Vue {
    public userRole: any = 'staff'
    public userUrl:any = 'staff'
    @Prop({ default: {} }) pickl!: any
    @Prop({ default: false }) disabled!: boolean

    mounted () {
      let userData = this.$store.state.userData
      this.userRole = userData.role
      if (this.userRole === 'super-admin') {
        this.userUrl = 'sadmin'
      } else {
        this.userUrl = 'staff'
      }
    }
    isSadminOrStaff () {
      return (this.$store.state.userData && this.$store.state.userData.role === 'super-admin') || (this.$store.state.userData && this.$store.state.userData.role === 'staff')
    }

    get statusText (): string {
      if (this.pickl.status === 'ACCEPTED') {
        return 'Accepted On'
      } else if (this.pickl.status === 'COMPLETED') {
        return 'Awaiting Approval'
      } else if (this.pickl.status === 'APPROVED' && this.pickl.user_pickl && this.pickl.user_pickl.need_review === 'No') {
        return 'Approved On'
      } else if (this.pickl.status === 'REJECTED' && this.pickl.user_pickl && this.pickl.user_pickl.need_review === 'No') {
        return 'Rejected On'
      } else if (this.pickl.status === 'PENDING') {
        return 'Created On'
      } else if (this.pickl.status === 'EXPIRED') {
        return 'Expired On'
      } else if (this.pickl.user_pickl && this.pickl.user_pickl.need_review === 'Yes' && this.pickl.status === 'APPROVED') {
        return 'Attention Needed + Approved On'
      } else if (this.pickl.user_pickl && this.pickl.user_pickl.need_review === 'Yes' && this.pickl.status === 'REJECTED') {
        return 'Attention Needed + Rejected On'
      } else {
        return ''
      }
    }

    get statusDate (): Date {
      return new Date(this.pickl.updated_at)
    }

    get statusDateString (): string {
      var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

      return this.statusDate.getDay() + ' ' + months[this.statusDate.getMonth()] + ' ' + this.statusDate.getFullYear()
    }

    get statusTimeString (): string {
      var ampm = 'AM'
      var hours: any = this.statusDate.getHours()
      var minutes: any = this.statusDate.getMinutes()

      if (hours >= 12) {
        ampm = 'PM'
        hours %= 12
      }

      if (hours < 10) hours = '0' + hours
      if (minutes < 10) minutes = '0' + minutes

      return hours + ':' + minutes + ' ' + ampm
    }

    get statusColor (): string {
      if (this.pickl.user_pickl && this.pickl.user_pickl.need_review === 'Yes' && (this.pickl.status === 'APPROVED' || this.pickl.status === 'REJECTED')) {
        return Vue.common.cardColors['ATTENTION']
      } else {
        return Vue.common.cardColors[this.pickl.status]
      }
    }
}
